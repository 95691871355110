import styled from '@emotion/styled';
import { Table, Tbody, Td, Th, Tr } from 'components/atoms/table';
import { AboutNav } from 'components/organisms/aboutNav';
import { AboutSection } from 'components/organisms/aboutSection';
import { ContentWrapper } from 'components/organisms/contentWrapper';
import FadeUpItem from 'components/organisms/fadeUpItem';
import InquiryBlock from 'components/organisms/inquiryBlock/';
import { graphql } from 'gatsby';
import * as React from 'react';
import { baseTransitionSecond, mq } from 'style/variables';
import { AboutCompanyEnQuery } from 'types/graphql-types';
import SEO from 'util/seo';

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface Props {
  data: AboutCompanyEnQuery;
}

const CompanySection = styled(AboutSection)`
  a {
    text-decoration: underline;
    transition: opacity ${baseTransitionSecond}s ease;
    ${mq.onlypc} {
    &:hover {
      opacity: 0.5;
    }
  }

  ${mq.onlysp} {
    padding-top: 0;
  }
`;

const Pages: React.FC<Props> = props => {
  const profiles = props.data.allShifterProfilesEn.edges;
  return (
    <>
      <SEO title={'Company | About'} en />
      <ContentWrapper>
        <AboutNav
          currentPage="Company"
          links={[
            { text: 'Company', link: '/en/about/company', active: true },
            { text: 'Studios', link: '/en/about/studios' },
            { text: 'Vision', link: '/en/about/vision' },
            { text: 'Culture', link: '/en/about/culture' },
            { text: 'Sustainability', link: '/en/about/sustainability' },
          ]}
        />
        <CompanySection>
          <FadeUpItem delay={0.6}>
            <Table>
              <Tbody>
                {profiles.map((profile, key) => (
                  <Tr key={key}>
                    <Th>{profile.node.title?.rendered || ''}</Th>
                    <Td dangerouslySetInnerHTML={{ __html: profile.node.acf?.text || '' }} />
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </FadeUpItem>
        </CompanySection>
      </ContentWrapper>

      <InquiryBlock en />
    </>
  );
};

export default Pages;

export const pageQuery = graphql`
  query AboutCompanyEn {
    site {
      siteMetadata {
        title
      }
    }
    allShifterProfilesEn(sort: { fields: acf___show_num, order: ASC }) {
      edges {
        node {
          id
          acf {
            text
          }
          title {
            rendered
          }
        }
      }
    }
  }
`;
